/* Add this CSS to your stylesheet */

/* navHeader.css */

/* Hide mobile logo on large screens */
.desktop-logo {
  display: none;
}

.mobile-logo {
  display: inline-block;
  height: 100%;
  width: auto;
}

/* Show desktop logo and hide mobile logo on lg and greater screens */
@media (min-width: 992px) {
  .desktop-logo {
    display: inline-block;
    width: 95%;
    height: auto;
  }

  .mobile-logo {
    display: none;
  }
}